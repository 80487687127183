import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Flex from "./Flex"
import Box from "./Box"
import colors from "../styles/colors"

const Container = styled(Flex)`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  line-height: 1.65rem;
  align-items: center;

  ${(props) =>
    props.color === "success" &&
    `
    background: ${colors.success.light};
    color: ${colors.success.dark};
    border-color: ${colors.success.normal};
    
    a,
    a:hover {
      color: ${colors.success.dark};
    }
  `};
`

const Alert = ({ title, message, icon, color }) => (
  <Container color={color}>
    <Box marginRight={20}>{icon}</Box>
    <Box>
      <Box as={"strong"}>{title}</Box>
      <Box>{message}</Box>
    </Box>
  </Container>
)

Alert.propTypes = {
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
}

export default Alert
