import React from "react"
import { FaCheckCircle } from "react-icons/fa"
import Page from "../components/Page"
import Box from "../components/Box"
import Alert from "../components/Alert"
import Link from "../components/Link"

const Goodbye = () => (
  <Page title={"Goodbye!"}>
    <Box>
      <Alert
        icon={<FaCheckCircle />}
        color={"success"}
        title={
          "Your ChangeHub account has been successfully closed and your data has been deleted."
        }
        message={
          <>
            We are sad to see you go but you are welcome back any time. Please{" "}
            <Link external to={"mailto:changehub@madewithlove.com"}>
              let us know
            </Link>{" "}
            if there is anything we can improve.
          </>
        }
      />
    </Box>
  </Page>
)

export default Goodbye
